<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <role-group-form :role-group="roleGroup">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </role-group-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import RoleGroupForm from "../../../components/forms/role-group-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";

export default {
    components: {CallbackButton, RoleGroupForm},

    computed: {
        ...mapGetters({
            roleGroup: 'roleGroup/item',
            groups: 'roleGroup/all'
        }),

        title: function () {
            return this.roleGroup.id ? this.$tc('authorization::role_groups.role_group', 2).ucFirst() + ' / ' + this.roleGroup.name : this.$t('base.create_new_item', {item: this.$tc('authorization::role_groups.role_group', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/authorization/role-groups')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (!this.groups || !this.groups.length) {
            this.$store.dispatch('roleGroup/all');
        }
        if (this.$route.params.id) {
            this.$store.dispatch('roleGroup/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('roleGroup/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
